import Footer from "../../components/Footer";
import DmPharmaContent from "../../components/ManufacturingUnit/DmPharmaContent";
import Navbar from "../../components/Navbar";

export default function DmPharma() {
    return (
        <>
            <Navbar />
            <DmPharmaContent />
            <Footer />
        </>
    )
}