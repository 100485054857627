import { useMemo } from "react";
//import breakpoints from "./breakpoints";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export default function AppThemeProvider({ children }) {
    const themeOptions = useMemo(() => ({
        // breakpoints,
        palette: {
            mode: 'light'
        },
        primary: {
            color: '#EF3E00'
        }
    }), []);

    const theme = createTheme(themeOptions);

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}