import { useEffect } from "react";
import ContactContent from "../components/ContactContent";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export default function Contact() {
    useEffect(() => {
        document.title = "Contact Us - Saar Biotech"
    }, []);

    return (
        <>
            <Navbar />
            <ContactContent />
            <Footer />
        </>
    )
}