import Expo2013Content from "../../components/Events/Expo2013Content";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

export default function Expo2013() {
    return (
        <>
            <Navbar />
            <Expo2013Content />
            <Footer />
        </>
    )
}