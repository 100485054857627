import { Container, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { FiberManualRecord } from "@mui/icons-material";

export default function ImmunitySpray() {
    return (
        <>
            <Navbar />


            <Toolbar />
            <Typography variant="h4" className="text-center color-primary">Immunity Booster Spray</Typography>
            <Toolbar />

            <Container maxWidth="md">
                <Typography variant="subtitle1" className="fw-600 mb-2">
                    Indications - Boost your immune system
                </Typography>

                <Typography variant="h6" className="color-primary mb--25 mt-4 text-uppercase">
                    Approved Name - <span className="text-dark">Immunity Booster Spray</span>
                </Typography>

                <Typography variant="button" className="font--size-1125">
                    Spray Presentation:
                </Typography>

                <Typography className="font--size-1125">
                    Vitamin C 40 mg
                </Typography>
                <Typography className="font--size-1125">
                    Zinc Sulphate 5.7 mg
                </Typography>
                <Typography className="font--size-1125">
                    Ashwagandha 25 mg
                </Typography>
                <Typography className="font--size-1125">
                    Giloy 20 mg
                </Typography>
                <Typography className="font--size-1125">
                    Astaxanthin 5 mg
                </Typography>
                <Typography className="font--size-1125">
                    Vitamin B1 1 mg
                </Typography>
                <Typography className="font--size-1125">
                    Vitamin D 10 mcg
                </Typography>

                <Typography className="my-3">
                    Our Immunity spray is mainly designed to boost up immunity and prevent damage from free radicals, use this spray to help boost your immune system, especially as the cold and flu season goes on the attack. The functions of all nutrients are well known, and each of them plays one or more key roles in maintaining the daily functions basics to health and life itself.<br />

                    Our immunity spray produces rapid and sustained relief, allowing you to recover quickly and be back in your daily life.<br />

                    This spray is easier to take for those people who cannot or do not like taking pills, capsules, or any other dosage form. This is also especially useful for those who experience difficulty in swallowing tablets.<br />

                    The rational use of nutritional supplements, combined with a healthy diet, will contribute substantially to health promotion and work in balance and synergism on protection and integration of the physiological functions of the body.<br />

                    Antioxidants present in our Immunity Spray are essential for sustaining a resilient immune system because they help defend your cells from free radicals — abnormal cells gone rogue which cause damage to other healthy cells.<br />
                </Typography>

                <Typography variant="h6" className="color-primary mt-4 text-uppercase">
                    Indications
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord className="color-primary icon-small" />
                        </ListItemIcon>
                        <ListItemText>
                            Antioxidant activity
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord className="color-primary icon-small" />
                        </ListItemIcon>
                        <ListItemText>
                            Boosts your energy levels and helps you combat the weakness
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord className="color-primary icon-small" />
                        </ListItemIcon>
                        <ListItemText>
                            Promotes normal growth and development by increasing collagen formation
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord className="color-primary icon-small" />
                        </ListItemIcon>
                        <ListItemText>
                            It provides protection against pollutants
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord className="color-primary icon-small" />
                        </ListItemIcon>
                        <ListItemText>
                            Improve the function of neurons as an antioxidant
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord className="color-primary icon-small" />
                        </ListItemIcon>
                        <ListItemText>
                            Help keep blood sugar and cholesterol levels
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord className="color-primary icon-small" />
                        </ListItemIcon>
                        <ListItemText>
                            Promotes skin health through increased collagen formation
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FiberManualRecord className="color-primary icon-small" />
                        </ListItemIcon>
                        <ListItemText>
                            Minimize digestive complaints
                        </ListItemText>
                    </ListItem>
                </List>

                <Typography variant="h6" className="color-primary mb--25 mt-4 text-uppercase">
                    Main Features
                </Typography>

                <Typography>
                    Immunity spray fits into any schedule and can be taken anywhere.
                </Typography>

                <Typography variant="h6" className="color-primary mb--25 mt-4 text-uppercase">
                    Description
                </Typography>

                <Typography>
                    <b>Ashwagandha</b> strengthens immune function by improving the cell-mediated immunity.<br />
                    <b>Giloy</b> helps in boosting up the immune system and helps to remove extra toxins from the body and rejuvenates the body.<br />
                    <b>Vitamin B1</b> Metabolites of carbohydrate, keeps heart and brain healthy.<br />
                    <b>Vitamin D</b> helps in calcium absorption to build strong bones.<br />
                    <b>Vitamin C and Zinc</b> contribute to your normal functioning of the immune system and both are required for healthy cell division and protein synthesis.
                </Typography>

                <Typography variant="h6" className="color-primary mb--25 mt-4 text-uppercase">
                    Side Effects
                </Typography>


                <Typography>
                    The product is possible safe for most adults. There is no disadvantage associated with the product if taken under the recommended usage. However, some are: headache, nausea, vomiting, and upset stomach.
                    If symptoms persist for a longer time, consult your dietician.
                </Typography>


                <Typography variant="h6" className="color-primary mb--25 mt-4 text-uppercase">
                    Warnings
                </Typography>

                <Typography>
                    Pregnant and lactating mothers should not take any single supplement in higher – than – normal doses unless recommended by a health – care provider for special conditions. The combination should only be used in pregnancy and lactation if benefits outweigh the risk.
                </Typography>


                <Typography variant="h6" className="color-primary mb--25 mt-4 text-uppercase">
                    Dosage
                </Typography>

                Use 4 Spray a day or as directed by the Dietician

                <Toolbar />

                <Typography className="fst-italic fw-600">
                    Disclaimer: The data uploaded is made from content already available on internet. The company holds no right of it and is not responsible for any wrong information.
                </Typography>

                <Toolbar />
            </Container >


            <Footer />
        </>
    )
}