import './App.css';
import AppRoutes from './Routes';
import './fonts/fonts.css';
import AppThemeProvider from './theme';

function App() {
  return (
    <>
      <AppThemeProvider>
        <AppRoutes />
      </AppThemeProvider>
    </>
  );
}

export default App;
